import React, { useRef, useEffect, useState, useCallback } from 'react';
import MarkdownIt from 'markdown-it';
import markdownItFootnote from 'markdown-it-footnote';
import markdownItTaskLists from 'markdown-it-task-lists';
import markdownItKaTeX from '@iktakahiro/markdown-it-katex';
import DOMPurify from 'dompurify';
import { PreviewToolbar } from './PreviewToolbar';
import { useEditorContext } from '../hooks/useEditorContext';
import { highlightCode } from '../utils/highlight';
import toast from 'react-hot-toast';
import 'prismjs/themes/prism-tomorrow.css';
// Add KaTeX CSS for math rendering
import 'katex/dist/katex.min.css';

interface PreviewProps {
  content: string;
  onScroll?: (scrollInfo: ScrollInfo) => void;
  syncScroll: boolean;
  scrollPosition?: ScrollInfo;
}

interface ScrollInfo {
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}

interface ContentStats {
  words: number;
  characters: number;
  charactersNoSpaces: number;
  lines: number;
}

// Initialize markdown-it instance with plugins and Prism.js highlighting
const md = new MarkdownIt({
  html: true,
  breaks: true,
  linkify: true,
  typographer: true,
  highlight: highlightCode,
})
  .use(markdownItFootnote) // Add footnote support
  .use(markdownItTaskLists) // Add task list support
  .use(markdownItKaTeX, { throwOnError: false, errorColor: '#cc0000' }); // Add KaTeX math rendering

// Calculate content stats from rendered HTML text
const calculateStats = (htmlElement: HTMLDivElement | null, hasContent: boolean): ContentStats => {
  if (!htmlElement || !hasContent) {
    return {
      words: 0,
      characters: 0,
      charactersNoSpaces: 0,
      lines: 0,
    };
  }

  const renderedText = htmlElement.innerText;
  const trimmedText = renderedText.trim();

  return {
    words: trimmedText ? trimmedText.split(/\s+/).filter(word => word.length > 0).length : 0,
    characters: renderedText.length,
    charactersNoSpaces: renderedText.replace(/\s/g, '').length,
    lines: trimmedText ? trimmedText.split('\n').length : 0,
  };
};

export const Preview: React.FC<PreviewProps> = ({
  content,
  onScroll,
  syncScroll,
  scrollPosition,
}) => {
  const previewRef = useRef<HTMLDivElement>(null);
  const [sanitizedHtml, setSanitizedHtml] = useState<string>('');
  const [copyHtmlInProgress, setCopyHtmlInProgress] = useState(false);
  const [copyTextInProgress, setCopyTextInProgress] = useState(false);
  const scrollTimeoutRef = useRef<number | null>(null);
  const isUserScrollingRef = useRef(false);
  const [stats, setStats] = useState<ContentStats>({
    words: 0,
    characters: 0,
    charactersNoSpaces: 0,
    lines: 0,
  });
  const { state: { isMaximized } } = useEditorContext();

  // Generate HTML with sanitization
  const generateHtml = useCallback(async (markdown: string) => {
    const rawHtml = md.render(markdown);
    return DOMPurify.sanitize(rawHtml, {
      ALLOWED_TAGS: [
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'br', 'hr',
        'a', 'img', 'pre', 'code', 'blockquote', 'em', 'strong',
        'ul', 'ol', 'li', 'table', 'thead', 'tbody', 'tr', 'th', 'td',
        'div', 'span', 'sup', 'sub', 'del', 'iframe',
        'input', 'label', 'form', 'select', 'option', 'button',
        // Add KaTeX-specific tags
        'span', 'math', 'mi', 'mo', 'mn', 'mfrac', 'msqrt', 'mrow', 'msup', 'msub',
      ],
      ALLOWED_ATTR: [
        'href', 'src', 'alt', 'title', 'class', 'target', 'rel',
        'data-lang', 'data-line', 'data-line-offset',
        'charset', 'style', 'border', 'align', 'cellpadding',
        'cellspacing', 'name', 'width', 'height',
        'allowfullscreen', 'frameborder', 'id', 'type',
        'value', 'checked', 'selected', 'disabled',
        'placeholder', 'maxlength', 'required', 'pattern',
        // Add KaTeX-specific attributes
        'display', 'xmlns',
      ],
      USE_PROFILES: {
        html: true,
        svg: false,
        svgFilters: false,
        mathMl: false, // Not using MathML directly, but KaTeX uses some similar tags
      },
      KEEP_CONTENT: true,
      FORBID_TAGS: ['style', 'script', 'object', 'embed', 'applet'],
      FORBID_ATTR: ['onerror', 'onload', 'onclick', 'onmouseover'],
      ALLOW_DATA_ATTR: false,
    });
  }, []);

  // Handle scroll sync
  useEffect(() => {
    if (!syncScroll || !scrollPosition || !previewRef.current || isUserScrollingRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollPosition;
    const previewElement = previewRef.current;
    const percentage = scrollTop / (scrollHeight - clientHeight);
    const targetScrollTop = percentage * (previewElement.scrollHeight - previewElement.clientHeight);

    requestAnimationFrame(() => {
      previewElement.scrollTop = targetScrollTop;
    });
  }, [scrollPosition, syncScroll]);

  // Update content and regenerate HTML when content changes
  useEffect(() => {
    generateHtml(content).then(setSanitizedHtml);
  }, [content, generateHtml]);

  // Update stats when HTML is rendered or content changes
  useEffect(() => {
    const hasContent = Boolean(content.trim());
    const timeoutId = setTimeout(() => {
      setStats(calculateStats(previewRef.current, hasContent));
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [sanitizedHtml, content]);

  // Cleanup scroll timeout
  useEffect(() => {
    return () => {
      if (scrollTimeoutRef.current) {
        window.clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  // Copy handlers with improved error handling
  const handleCopyText = useCallback(async () => {
    if (copyTextInProgress || !previewRef.current) return;

    try {
      setCopyTextInProgress(true);
      const previewText = previewRef.current.innerText;
      await navigator.clipboard.writeText(previewText);
      toast.success('Copied as text', {
        duration: 2000,
        style: { background: '#18181b', color: '#fff' },
      });
    } catch (error: unknown) {
      console.error('Failed to copy text:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to copy text');
    } finally {
      setTimeout(() => setCopyTextInProgress(false), 1000);
    }
  }, [copyTextInProgress]);

  const handleCopyHtml = useCallback(async () => {
    if (copyHtmlInProgress) return;

    try {
      setCopyHtmlInProgress(true);
      const cleanHtml = DOMPurify.sanitize(sanitizedHtml, {
        USE_PROFILES: { html: true },
      });
      await navigator.clipboard.writeText(cleanHtml);
      toast.success('Copied as HTML', {
        duration: 2000,
        style: { background: '#18181b', color: '#fff' },
      });
    } catch (error: unknown) {
      console.error('Failed to copy HTML:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to copy HTML');
    } finally {
      setTimeout(() => setCopyHtmlInProgress(false), 1000);
    }
  }, [sanitizedHtml, copyHtmlInProgress]);

  // Handle download text
  const handleDownloadText = useCallback(() => {
    if (!previewRef.current || !previewRef.current.innerText.trim()) {
      toast.error('No content to download');
      return;
    }

    try {
      const renderedText = previewRef.current.innerText;
      const blob = new Blob([renderedText], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      a.download = `document-${timestamp}.txt`;
      a.click();
      URL.revokeObjectURL(url);
      toast.success('Document downloaded successfully');
    } catch (error: unknown) {
      console.error('Failed to download text:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to download document');
    }
  }, []);

  // Handle scroll events
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    if (!syncScroll || !onScroll) return;

    isUserScrollingRef.current = true;
    if (scrollTimeoutRef.current) {
      window.clearTimeout(scrollTimeoutRef.current);
    }

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    onScroll({ scrollTop, scrollHeight, clientHeight });

    scrollTimeoutRef.current = window.setTimeout(() => {
      isUserScrollingRef.current = false;
      scrollTimeoutRef.current = null;
    }, 100);
  }, [syncScroll, onScroll]);

  return (
    <div
      className={`flex flex-col h-[40vh] md:h-full bg-white dark:bg-gray-900 ${
        isMaximized ? 'fixed inset-4 z-50' : ''
      }`}
      role="region"
      aria-label="Preview"
    >
      {/* Toolbar */}
      <PreviewToolbar
        onCopyText={handleCopyText}
        onCopyHtml={handleCopyHtml}
        onDownloadText={handleDownloadText}
        copyTextInProgress={copyTextInProgress}
        copyHtmlInProgress={copyHtmlInProgress}
        hasContent={Boolean(content.trim())}
      />

      {/* Preview Content */}
      <div className="flex-1 min-h-0 relative">
        <div className="absolute inset-0 overflow-hidden">
          <div
            ref={previewRef}
            className="h-full w-full overflow-y-auto"
            onScroll={handleScroll}
            data-testid="preview-content"
          >
            <article
              className="w-full px-4 py-4 prose bg-white"
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            />
          </div>
        </div>
      </div>

      {/* Stats Bar */}
      <div className="bg-gray-100 dark:bg-gray-800 p-2 text-sm text-gray-800 dark:text-gray-200 flex justify-between items-center border-t border-gray-200 dark:border-gray-700">
        <div>
          {stats.words.toLocaleString()} words | {stats.characters.toLocaleString()} characters | {stats.lines.toLocaleString()} lines
        </div>
      </div>
    </div>
  );
};

export default Preview;